/**
 * 程序版本：V2.0
 * 版权所有：深圳市科飞时速网络科技有限公司
 * 技术支持：Tech@21gmail.com
 * 单元名称：客户·公海客户页面js
 * 开始时间：2017-11-9
 * 开发人员：陈立珍
 * 最后修改：2018-12-06
 * 备注说明：如需修改请联系开发人员
 */

import moreInfo from "../../components/crm/CommonItem/crmMoreInfo.vue" //详细信息弹窗
import smallLayer from "../../components/crm/CommonItem/smallLayer.vue" //弹窗
import commonSearch from "../../components/crm/CommonItem/searchBar.vue" //搜索框
import { Message } from 'element-ui';
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import '../../vuex/mutations.js';
export default({
	name: "crm-content",
	data() {
		return {
			customer_id: 0, //客户id
			user_id:0,//用户id
			currpage: 1, //当前页
			tolpage: 1, //总页数
			tolRecord: 1, //总条数
			pagesize: 10, //每页显示数量
			cusUserid: 0, // 当前登录用户ID或者选择查看的员工ID
			cusType: 0, // 查询类型：0全部公海，1已成交公海，2未成交公海
			cusKeyword: "", // 搜索内容
			//公海客户类型下拉框
			pCusTypeList: [{
					text: "全部公海用户",
					value: 0
				},
				{
					text: "已成交公海用户",
					value: 1
				},
				{
					text: "未成交公海用户",
					value: 2
				}
			],
			//公海客户用户下拉框
			userListArr: [],
			//加载框
			autographLoading: false,
			//选择的checkbox,客户id###用户id
			selectArr: [],
			showOperation: false, //是否显示删除客户|转移客户操作,false隐藏,true显示

			test: false,
			showC: false,
			showG: false,

			myTitle: [{
				name: "公海客户"
			}],
			infoLists: [], //客户列表集合
			clientList: [], //转移的用户列表
			tipData: [{
				text1: "月剩余领取公海客户数量",
				text2: " , 日剩余领取公海客户数量",
				text3: " , 确定要领取这",
				text4: "个客户吗？",
				num1: " 1000 ",
				num2: " 100 ",
				num3: " 1 "
			}],
			customerIds: [], //客户id
			is_yunying: 0, //当前用户是否是运营 0：不是  1：是
		}
	},
	components: {
		moreInfo,
		'small-layer': smallLayer,
		commonSearch
		//commonTable
	},
	computed: {
		...mapState({
			userinfo: state => state.userinfo,
			customerclassifyall: state => state.customerclassifyall,
			customerstatusall: state => state.customerstatusall,
			userlistall: state => state.userlistall,
			loadCusNumTag: state => state.loadCusNumTag,
		})
	},
	methods: {
		...mapMutations({
			getCustomerClassifyAll: 'getCustomerClassifyAll', //加载客户分类
			getCustomerStatusAll: 'getCustomerStatusAll', //加载客户状态
			getUserlistAll: 'getUserlistAll', //加载企业下所有用户
			getUserInfo:'getUserInfo'
		}),
		//接收公共搜索点击事件
		searchInputClick(searchInput) {
			this.cusKeyword = searchInput;
			this.pagesize = 10;
			this.currpage = 1;
			this.commonAjax();
		},

		//点击企业的名称弹出框
		opens: function(cus_id,uid) {
			this.customer_id = cus_id;
			this.user_id = uid;
			this.test = true;
		},
		//关闭弹窗
		close: function(msg) {
			this.test = msg;
		},
		//删除
		checkDelete: function() {
			//判断是否选中客户列表
			if(this.selectArr.length == 0) {
				this.$message({
					showClose: true,
					message: '请选择客户列表!',
					type: 'warning',
					duration: 2000
				});
				return;
			}
			//判断选择的列表中的客户是否被锁定
			var is_lock = ''; //是否被锁定
			$.each(this.selectArr, function(i, row) {
				is_lock += row.split('###')[2] + ',';
			});
			is_lock = is_lock.substring(0,is_lock.length-1);
			if(is_lock.indexOf('1') != -1){
				this.$message({
					showClose: true,
					message: '选择的客户列表中包含被锁定的客户!',
					type: 'warning',
					duration: 2000
				});
				return;
			}
			this.$confirm('确定删除?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
				closeOnClickModal: false,
				cancelButtonClass: 'MBcancelBtn'
			}).then(() => {
				var cud_ids = ''; //客户id
				var user_ids = ''; //用户id
				$.each(this.selectArr, function(i, row) {
					cud_ids += row.split('###')[0] + ',';
					user_ids += row.split('###')[1] + ',';
				});
				cud_ids = cud_ids.substring(0, cud_ids.length - 1);
				user_ids = user_ids.substring(0, user_ids.length - 1);
				var lef = this;
				$.ajax({
					type: 'post',
					url: backUrl + 'cusCP_deletePublic.action',
					data: {
						token: token,
						customerids: cud_ids,
						userid: user_ids
					},
					success: function(msg) {
						if(msg == 1) {
							lef.$message({
								showClose: true,
								message: '删除成功！',
								type: 'success',
								duration: 2000
							});
							lef.selectArr = [];
							lef.commonAjax(); //重新加载公海客户列表
							//刷新公海客户数量
							lef.$store.state.loadCusNumTag = 2;
						} else {
							lef.$message({
								showClose: true,
								message: '删除失败！',
								type: 'error',
								duration: 2000
							});
						}
					},
					error: function() {
						lef.$message({
							showClose: true,
							message: '删除失败',
							type: 'error',
							duration: 2000
						});
					}
				})
			}).catch(() => {});
		},
		//全选
		selectAll(event) {
			var _this = this;
      // if(!event.currentTarget.checked) {
			if(!event) {
				//反选
				this.selectArr = [];
			} else { //实现全选
				_this.selectArr = [];
				_this.infoLists.forEach(function(item, i) {
					_this.selectArr.push(item.customer);
				});
			}
		},
		//转移
		checkTransfer: function() {
			if(this.selectArr.length == 0) {
				this.$message({
					showClose: true,
					message: '请选择客户列表!',
					type: 'warning',
					duration: 2000
				});
				return;
			}
			var user_id_arr = []; //用户id
			var first_user_id = 0; //第一个用户id
			var is_lock = ''; //是否被锁定
			$.each(this.selectArr, function(i, row) {
				if(i == 0) {
					first_user_id = row.split('###')[1];
				}
				user_id_arr.push(row.split('###')[1]);
				is_lock += row.split('###')[2] + ',';
			});
			//判断选择的列表中的客户是否被锁定
			is_lock = is_lock.substring(0,is_lock.length-1);
			if(is_lock.indexOf('1') != -1){
				this.$message({
					showClose: true,
					message: '选择的客户列表中包含被锁定的客户!',
					type: 'warning',
					duration: 2000
				});
				return;
			}
			var is_same = true;
			$.each(user_id_arr, function(key, value) {
				if(first_user_id != value) {
					is_same = false;
					return false;
				}
			});
			if(!is_same) {
				this.$message({
					showClose: true,
					message: '只能转移一个用户的公海客户,您已经选择了多个用户的公海客户!',
					type: 'warning',
					duration: 2000
				});
				return;
			}
			var lef = this;
			lef.clientList = [];
			$.each(lef.userlistall, function(i, user) {
				lef.clientList.push({
					'user_id': user.user_id,
					'name': user.user_name
				});
			});
			lef.showC = true;
		},
		//转移提交
		cntConfirm: function(user_id,isMoveMail) {
			var cus_ids = ''; //客户id
			$.each(this.selectArr, function(i, row) {
				cus_ids += row.split('###')[0] + ',';
			});
			cus_ids = cus_ids.substring(0, cus_ids.length - 1);
			var movemail = 0;
			if(isMoveMail){  //表示转移
				movemail = -1;
			}
			var lef = this;
			// 先判断转移给的用户的客户数量情况
			$.ajax({
				"type": "POST",
				//				"async" : false,
				"cache": false,
				"url": backUrl + "cus_checkCustomerCounts.action",
				"data": {
					token: token,
					customerid: cus_ids,
					upid: user_id
				},
				beforeSend: function() {
					lef.$store.state.popupLoading = true;
				},
				complete: function() {
					lef.$store.state.popupLoading = false;
				},
				success: function(msg) {
					if(msg[0] == 1) {
						// 先添加客户生命周期信息
						$.ajax({
							type: "POST",
							url: backUrl + "xcus_addLifeCyclesByCusIds.action",
							async: true,
							cache: false,
							data: {
								token: token,
								customer_ids: cus_ids, //客户id
								owner_type: 2, //类型
								target_id: user_id //目标用户id
							},
							dataType: "json",
							cache: false,
						});

						//调用ajax
						$.ajax({
							type: "POST",
							url: backUrl + "cusCP_movePublic.action",
							data: {
								token: token,
								customerids: cus_ids,
								upuserid: user_id,
								ismoveorreceive: 0,
								movemail:movemail
							},
							dataType: "json",
							cache: false,
							success: function(msg) {
								if(msg == 1) {
									lef.$message({
										showClose: true,
										message: "转移成功！",
										type: 'success',
										duration: 2000
									});
									lef.selectArr = [];
									lef.commonAjax(); //重新加载公海客户列表
									if(lef.userinfo.user_id == user_id) {
										//刷新公海客户数量&&全部客户数量&&各分类客户数量&&各状态客户数量&&已入库联系数量
										lef.$store.state.loadCusNumTag = 1;
									} else {
										//刷新公海客户数量
										lef.$store.state.loadCusNumTag = 2;
									}
								} else {
									lef.$message({
										showClose: true,
										message: "转移客户失败，请刷新界面后重试！",
										type: 'warning',
										duration: 2000
									});
								}
							},
							error: function(e) {
								lef.$message({
									showClose: true,
									message: "转移客户失败，请刷新界面后重试！",
									type: 'warning',
									duration: 2000
								});
							}
						});
					} else if(msg[0] == -1) { // 总数量超标
						lef.$message({
							showClose: true,
							message: msg[1],
							type: 'warning',
							duration: 2000
						});
					} else if(msg[0] == -2) { // 日数量超标
						lef.$message({
							showClose: true,
							message: msg[1],
							type: 'warning',
							duration: 2000
						});
					} else {
						lef.$message({
							showClose: true,
							message: "转移客户失败，请刷新界面后重试！",
							type: 'warning',
							duration: 2000
						});
					}
				},
				error: function() {
					lef.$message({
						showClose: true,
						message: "转移客户失败，请刷新界面后重试！",
						type: 'warning',
						duration: 2000
					});
				}
			});
		},
		//领取
		getClient: function() {
			if(this.selectArr.length == 0) {
				this.$message({
					showClose: true,
					message: '请选择客户列表!',
					type: 'warning',
					duration: 2000
				});
				return;
			}
			var user_id_arr = []; //用户id
			var cus_ids = ''; //客户id
			var first_user_id = 0; //第一个用户id
			var is_lock = ''; //是否被锁定

			$.each(this.selectArr, function(i, row) {
				if(i == 0) {
					first_user_id = row.split('###')[1];
				}
				cus_ids += row.split('###')[0] + ',';
				user_id_arr.push(row.split('###')[1]);
				is_lock += row.split('###')[2] + ',';
			});
			cus_ids = cus_ids.substring(0, cus_ids.length - 1);
			//判断选择的列表中的客户是否被锁定
			is_lock = is_lock.substring(0,is_lock.length-1);
			if(is_lock.indexOf('1') != -1){
				this.$message({
					showClose: true,
					message: '选择的客户列表中包含被锁定的客户!',
					type: 'warning',
					duration: 2000
				});
				return;
			}
			if($.inArray(this.userinfo.user_id + '', user_id_arr) > -1) {
				this.$message({
					showClose: true,
					message: '不能领取自己的公海客户,请选择其他人的公海客户!',
					type: 'warning',
					duration: 2000
				});
				return;
			}
			var is_same = true;
			$.each(user_id_arr, function(key, value) {
				if(first_user_id != value) {
					is_same = false;
					return false;
				}
			});
			if(!is_same) {
				this.$message({
					showClose: true,
					message: '只能领取一个用户的公海客户,您已经选择了多个用户的公海客户!',
					type: 'warning',
					duration: 2000
				});
				return;
			}
			var lef = this;
			$.ajax({
				type: "POST",
				url: backUrl + "cusCP_getSurplusNums.action",
				dataType: "json",
				data: {
					token: token,
				},
				cache: false,
				success: function(msg) {
					if(msg[0] == 0) //月领取公海客户为0
					{
						lef.$message({
							showClose: true,
							message: '月剩余领取公海客户数量 :[ 0 ],日剩余领取公海客户数量:[ ' + msg[1] + ' ]',
							type: 'warning',
							duration: 2000
						});
					} else if(msg[1] == 0) //日领取公海客户为0
					{
						lef.$message({
							showClose: true,
							message: '月剩余领取公海客户数量 :[ ' + msg[0] + ' ],日剩余领取公海客户数量:[ 0 ]',
							type: 'warning',
							duration: 2000
						});
					} else //日和月都足够
					{
						lef.$confirm('月剩余领取公海客户数量:[ ' + msg[0] + ' ],日剩余领取公海客户数量:[ ' + msg[1] + ' ],确定要领取这:[ ' + lef.selectArr.length + ' ]个客户吗？', '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning',
							closeOnClickModal: false,
							cancelButtonClass: 'MBcancelBtn'
						}).then(() => {
							// 先判断当前用户的客户数量情况
							$.ajax({
								"type": "POST",
								"async": false,
								"cache": false,
								"url": backUrl + "cus_checkCustomerCounts.action",
								"data": {
									token: token,
									customerid: cus_ids,
									upid: lef.userinfo.user_id
								},
								success: function(msg) {
									if(msg[0] == 1) {
										// 先添加客户生命周期信息
										$.ajax({
											type: "POST",
											url: backUrl + "xcus_addLifeCyclesByCusIds.action",
											async: true,
											cache: false,
											data: {
												token: token,
												customer_ids: cus_ids, //客户id
												owner_type: 3, //类型
												target_id: lef.userinfo.user_id //目标用户id
											},
											dataType: "json",
											cache: false,
										});

										$.ajax({
											type: "POST",
											url: backUrl + "cusCP_movePublic.action",
											data: {
												token: token,
												customerids: cus_ids,
												upuserid: lef.userinfo.user_id,
												ismoveorreceive: 1
											},
											dataType: "json",
											cache: false,
											success: function(msg) {
												if(msg == 1) {
													lef.$message({
														showClose: true,
														message: '领取成功！',
														type: 'success',
														duration: 2000
													});
													lef.selectArr = [];
													lef.commonAjax(); //重新加载公海客户列表
													//公海客户数量&&全部客户数量&&各分类客户数量&&各状态客户数量&&已入库联系数量
													lef.$store.state.loadCusNumTag = 1;
												} else {
													lef.$message({
														showClose: true,
														message: '领取失败,请刷新界面后重试!',
														type: 'warning',
														duration: 2000
													});
												}
											},
											error: function(e) {
												lef.$message({
													showClose: true,
													message: '领取失败,请刷新界面后重试!',
													type: 'warning',
													duration: 2000
												});
											}
										});
									} else if(msg[0] == -1) { // 总数量超标
										lef.$message({
											showClose: true,
											message: msg[1],
											type: 'warning',
											duration: 2000
										});
									} else if(msg[0] == -2) { // 日数量超标
										lef.$message({
											showClose: true,
											message: msg[1],
											type: 'warning',
											duration: 2000
										});
									} else {
										lef.$message({
											showClose: true,
											message: '领取失败,请刷新界面后重试!',
											type: 'warning',
											duration: 2000
										});
									}
								},
								error: function() {
									lef.$message({
										showClose: true,
										message: '领取失败,请刷新界面后重试!',
										type: 'warning',
										duration: 2000
									});
								}
							});
						}).catch(() => {});
					}
				},
				error: function(e) {
					lef.$message({
						showClose: true,
						message: '领取失败,请刷新界面后重试!',
						type: 'warning',
						duration: 2000
					});
				}
			});
		},
		//锁定和解锁的公共方法 1:锁定  0：解锁
		lockedMethod(value){
			if(this.selectArr.length == 0) {
				this.$message({
					showClose: true,
					message: '请选择客户列表!',
					type: 'warning',
					duration: 2000
				});
				return;
			}
			var cus_ids = ''; //客户id
			var is_lock = ''; //是否被锁定
			$.each(this.selectArr, function(i, row) {
				cus_ids += row.split('###')[0] + ',';
				is_lock += row.split('###')[2] + ',';
			});
			cus_ids = cus_ids.substring(0, cus_ids.length - 1);
			is_lock = is_lock.substring(0,is_lock.length-1);
			var lef = this;
			$.ajax({
				type: 'post',
				url: backUrl + 'cus_updateCustomerLockStatus.action',
				data: {
					token: token,
					customerid: cus_ids,
					is_lock: value
				},
				success: function(msg) {
					if(msg == 1) {
						lef.$message({
							showClose: true,
							message: '操作成功！',
							type: 'success',
							duration: 2000
						});
						lef.selectArr = [];
						lef.commonAjax(); //重新加载公海客户列表
					} else {
						lef.$message({
							showClose: true,
							message: '操作失败！',
							type: 'error',
							duration: 2000
						});
					}
				},
				error: function() {
					lef.$message({
						showClose: true,
						message: '操作异常',
						type: 'error',
						duration: 2000
					});
				}
			})
		},
		//页数改变执行
		siezChange(size) {
			this.pagesize = size;
			this.commonAjax(); //加载公海客户的列表
		},

		restore: function() {
			this.$emit('restore')
		},
		returnCrm() {
			this.$emit('returnCrm')
		},
		//数组去重
		removeRepeat(myarr, myprop) {
			for(let i = 0, max = this[myarr]; i < max.length; i++) {
				for(let j = 0, max = this[myarr]; j < max.length; j++) {
					if(max[i][myprop] == max[j][myprop]) {
						this[myarr].splice(i, 1);
					}
				}
			}
		},
		//选择员工进行查询
		selectEmpQuery() {
			this.pagesize = 10;
			this.currpage = 1;
			this.commonAjax(); //加载公海客户的列表
		},
		//加载公海客户的列表数据
		commonAjax() {
			let _this = this;
			_this.selectArr = []; //清空选择
			$.ajax({
				type: "post",
				url: backUrl + "cusCP_findAllPCustomer.action",
				async: true,
				cache: false,
				dataType: "json",
				beforeSend: function() {
					_this.autographLoading = true;
				},
				complete: function() {
					_this.autographLoading = false;
				},
				data: {
					token: token,
					pagesize: _this.pagesize,
					currpage: _this.currpage,
					value: _this.cusUserid + "," + _this.cusType + "," + _this.cusKeyword,
					is_yunying: _this.userinfo.is_yunying,
				},
				success: function(data) {
					_this.tolpage = data.pageBean.totalPage; //总页数
					_this.tolRecord = data.pageBean.totalRecord; //总条数
					_this.infoLists = [];
					let list = data.crmcustomerlist;
					for(let i in list) {
						var imgurl = backUrl + "img/male_user.png";
						if(list[i].img_url !== undefined && list[i].img_url !== null && list[i].img_url != 'null'  && list[i].img_url != "") {
							if(list[i].img_url.indexOf("http://")!=-1 || list[i].img_url.indexOf("https://")!=-1){
									imgurl = list[i].img_url
								}else{
									imgurl = backUrl + list[i].img_url
								}
						}
						var lastDate = "暂无活跃";
						if(list[i].last_contact_date != null && list[i].last_contact_date != '') {
							lastDate = list[i].last_contact_date.split(" ")[0];
						}
						//所属客户分类
						var classify_name = "未分类";
						$.each(_this.customerclassifyall, function(s, row) {
							if(row.id == list[i].classify_id) {
								classify_name = row.name;
								return false;
							}
						});
						//所属客户状态
						var status_name = "未分类";
						$.each(_this.customerstatusall, function(s, row) {
							if(row.id == list[i].customer_status) {
								status_name = row.name;
								return false;
							}
						});
						//所属用户
						var user_name = "未知用户";
						$.each(_this.userlistall, function(s, row) {
							if(row.user_id == list[i].user_id) {
								user_name = row.user_name;
								return false;
							}
						});

						var is_lock = "";
						if(list[i].is_lock == 1) {
							is_lock = "(被锁定)";
						}
						var customer_name = list[i].customer_name;
						if(customer_name.length > 40) {
							customer_name = customer_name.substring(0, 37) + is_lock + '...';
						} else {
							customer_name = customer_name + is_lock;
						}

						_this.infoLists.push({
							name: customer_name,
							email: list[i].email,
							date: lastDate,
							img_urls: imgurl,
							classify_name: classify_name,
							status_name: status_name,
							user_name: user_name,
							user_id: list[i].user_id,
							customer: list[i].customer_id + "###" + list[i].user_id + "###" + list[i].is_lock,
							customer_id: list[i].customer_id,
							is_lock: list[i].is_lock
						});
					}
				}
			})
		}
	},
	mounted() {

		this.getUserInfo();
		this.is_yunying = this.userinfo.is_yunying;
		//加载客户分类信息
		if(this.customerclassifyall == '') {
			this.getCustomerClassifyAll();
		}
		//加载客户状态
		if(this.customerstatusall == '') {
			this.getCustomerStatusAll();
		}
		//加载所有用户
		if(this.userlistall == '') {
			this.getUserlistAll();
		}
		var lef = this;
		$.each(lef.userlistall, function(i, user) {
			var user_name1 = user.user_name;
			if(user_name1.length > 7) {
				user_name1 = user_name1.substring(0, 7) + "...";
			}
			lef.userListArr.push({
				'user_id': user.user_id,
				'user_name': user.user_name,
				"user_name1": user_name1,
			});
		});
		lef.commonAjax(); //加载公海客户的列表
		if(this.userinfo.user_role == 1) { //企业级显示删除客户|转移客户操作
			this.showOperation = true;
		} else { //个人级隐藏删除客户|转移客户操作
			this.showOperation = false;
		}
	}
})
